import { useState, useEffect } from "react";

export function useBluetooth() {
  const [device, setDevice] = useState(null);
  const [server, setServer] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [temperature, setTemperature] = useState(null);
  const [status, setStatus] = useState('Click the button to connect to a BLE device');


  // Function to connect to BLE device
  const requestDevice = async () => {
    // KBPro_513242
    // KBPro_513207
    try {
      await connectToDevice()
    } catch (error) {
      console.error("Error connecting to device:", error);
    }
  };
  async function connectToDevice() {
    try {
      setStatus('Requesting BLE device...');
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true, // Accept all devices (or add filters)
        optionalServices: ['battery_service', 'device_information'] // Add services you want access to
      });

      setStatus('Connecting to GATT server...');
      const server = await device.gatt.connect();

      // Use a known service or device information
      const service = await server.getPrimaryService('device_information'); // Replace with the correct service UUID
      const characteristics = await service.getCharacteristics();

      console.log('Available Characteristics:', characteristics);
      
      // Loop through characteristics to find the one with temperature
      for (const characteristic of characteristics) {
        const value = await characteristic.readValue();
        const rawData = new Uint8Array(value.buffer);
        
        console.log(`Raw Data from characteristic ${characteristic.uuid}:`, rawData);
        
        // Check if this characteristic has the temperature
        const temperatureIndex = rawData.findIndex((byte, index, array) => 
          array[index] === 0x1E && array[index + 1] === 0x55 // Example temperature bytes (1E = 30)
        );

        if (temperatureIndex !== -1) {
          const tempHex = rawData[temperatureIndex]; // Extract byte (e.g., 0x1E)
          const temperature = tempHex; // Convert hex to decimal, 0x1E = 30
          setTemperature(temperature);
          setStatus(`Temperature: ${temperature}°C`);
          break;
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('Failed to connect or read data from the device.');
    }
  }


  // Function to disconnect BLE device
  const disconnectDevice = () => {
    if (device && device.gatt.connected) {
      device.gatt.disconnect();
      setIsConnected(false);
      console.log("Device disconnected");
    }
  };

  useEffect(() => {
    if (device) {
      device.addEventListener("gattserverdisconnected", () => {
        setIsConnected(false);
        console.log("Device disconnected");
      });
    }
    return () => {
      if (device) {
        device.removeEventListener("gattserverdisconnected", () => {
          setIsConnected(false);
        });
      }
    };
  }, [device]);

  return { requestDevice, disconnectDevice, isConnected };
}
