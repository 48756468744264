import React, { useEffect, useState } from "react";
import { useSensorContext } from "../../../contexts/sensor.context";
import constant, { BluetoothSensor } from "../../../helpers/constant";
import FeatureItem from "../../common/FeatureItem";
import { getStorageItem, setStorageItem } from "../../../helpers/shared-info";
import { useBluetooth } from "../../../hooks/useBluetooth";

const Bluetooth = () => {
  const existingData = getStorageItem(BluetoothSensor.id);
  const { recording, sendSensorData } = useSensorContext();
  const [active, setActive] = useState(existingData?.active);
  const { requestDevice, disconnectDevice, isConnected } = useBluetooth();

  useEffect(() => {
    if (active) {
      requestDevice();
    }
  }, [active]);

  useEffect(() => {
    const data = getStorageItem(BluetoothSensor.id);
    if (data && !data.frequency) {
      data.frequency = constant.defaultFrequency;
    }
    setStorageItem(BluetoothSensor.id, { ...data, active });
  }, [active]);

  const render = () => {
    return (
      <>
        <FeatureItem
          item={BluetoothSensor}
          active={active}
          setActive={() => setActive(!active)}
        />
        <p>status: {isConnected}</p>
      </>
    );
  };
  return render();
};

export default Bluetooth;
